import React from "react"
import Layout from "../components/Layout"
import { Container, Button } from "react-bootstrap"
import { HouseFill, TelephoneFill, EnvelopeFill, Globe2 } from 'react-bootstrap-icons'
import '../styles/contact.css'
import { useEffect } from "react";
import AOS from 'aos';
import Head from '../components/Head';



function Contact() {

  useEffect(() => {
    AOS.init({
      duration : 1000
    });
    AOS.refresh();

  }, []);

  return (
    <div className='overflow-hidden '>
      <Head title="Contact"/>
      <Layout>
        <Container  className="contact px-5" >

          <header className="sectionHeader halfBorder  my-5" as="h1">Contact Us</header>

          <section className="contactUs">
            <h1 data-title="ksss college" >Karnataka Shikshana Seva Samiti</h1>

            <div  style={{fontSize:"1.8rem"}} >
              <div className="d-flex mb-3" data-aos="fade-left" data-aos-duration='1000'>
                <span><HouseFill size={25} color="#5e1717"  className="me-4" /></span>

                <p>
                  P U Science and Commerce College <br/>
                  Budarsingi - 580 028 <br/>
                  TQ: Hubli, Karnataka(India)
                </p>
              </div>
              
              <div className="mb-3" data-aos="fade-left" data-aos-duration='1200'>
                <span><TelephoneFill size={22} color="#5e1717" className="me-4" /></span>
                <a href="tel:9945532549">Principal No: +91-99455 32459</a>
              </div>
              <div className="mb-3" data-aos="fade-left" data-aos-duration='1100'>
                <span><TelephoneFill size={22} color="#5e1717" className="me-4" /></span>
                <a href="tel:9945024923">Office No: +91-95911 64743</a>
              </div>
              
              <div className="mb-3" data-aos="fade-left" data-aos-duration='1300'>
                <span><EnvelopeFill size={22} color="#5e1717" className="me-4" /></span>
                <a href="mailto:kssscollege@gmail.com">Email-Id: kssscollege@gmail.com </a> 
              </div>
              <div className="" data-aos="fade-left" data-aos-duration='1400'>
                <span><Globe2 size={22} color="#5e1717" className="me-4" /></span>
                <a href="http://www.kssscollege.com/" target='_blank' rel="noreferrer">Web Site: www.kssscollege.com </a> 
              </div>
            
            </div>
          </section>


        </Container>

        <Container >
          <section className="contact-area row">
            
            <div className="map col-lg-6 col-12 ">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7697.157039944437!2d75.139239!3d15.290764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x69df05dcd9e18b5c!2sKSSS%20P.U%20Science%20and%20Commerce%20College!5e0!3m2!1sen!2sin!4v1643743429909!5m2!1sen!2sin" className="h-100" width="100%"  title="ksss college address"  loading="lazy" allowFullScreen="" ></iframe>
            </div>

            <div className="contactForm col-lg-6 col-12 d-flex justify-content-center align-items-center my-5 py-5">
              
              <div className="formSection">
                <div className="mb-5">
                  <h1 >Any question in mind? Don’t Worry  Contact Us.</h1>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeVMi0rLbgoq72UV7R-Vj5U2B8rV8y1fByTrS5nCEzR7RdrNQ/viewform" target='_blank' rel="noreferrer" className="text-light fs-1">
                    <Button className=" submitBtn btn mt-5  w-50 "  type="submit" style={{fontSize:'2rem'}}>Click here </Button>
                  </a> 
                  {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeVMi0rLbgoq72UV7R-Vj5U2B8rV8y1fByTrS5nCEzR7RdrNQ/viewform?embedded=true" width="640" height="955" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                </div>

                {/* <div className="bg-light" data-aos="zoom-in" >
                  <Form className='p-5' onSubmit={handleSubmit}>

                    <Row className="mb-4" >
                        <Form.Group  className='col-12 ' controlId="formBasicTextName">
                          <Form.Control
                              required
                              className='input'
                              type="text"
                              maxLength={20}
                              placeholder="Your Name"
                              title="Please enter Your Name"
                              name='name'
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              
                          />
                          </Form.Group>

                          <Form.Group  className='col-12' controlId="formBasicTextNumber">
                            <Form.Control
                              required
                              className='input'
                              type="text"
                              pattern="[0-9]{1}[0-9]{9}"
                              placeholder="Your Phone Number" 
                              title="Please enter 10 digit Mobile Number"
                              name='phoneNumber'
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </Form.Group>

                          <Form.Group  className='col-12' controlId="formBasicEmail">
                            <Form.Control
                                required
                                className='input'
                                type="email"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                placeholder="Your Email Address"
                                title="Please enter Email Address"
                                name='email'  
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                />
                          </Form.Group>

                          <Form.Group  className='col-12' controlId="formBasicTextarea">
                            <Form.Control
                                required
                                className='input overflow-auto'
                                as="textarea"
                                placeholder="Enter a Message"
                                style={{resize:'none'}}
                                rows={4} 
                                title="Please enter Message"
                                name='message' 
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                          </Form.Group>
                    </Row>


                    <div className="d-flex justify-content-center">
                        <Button className="submitBtn btn me-4 w-50 "    
                          type="submit"  
                          style={{fontSize:"2rem"}} 
                          // onClick = {onSubmit}

                        >
                            Send Message 
                        </Button>
                        <Button className=" resetBtn btn ms-4  w-50"  type="reset" style={{fontSize:'2rem'}}>Reset</Button>
                    </div>
                  </Form>
                </div> */}
              </div>
            </div>
          </section>
        
        </Container>

      </Layout>
    </div>

  )
}

export default Contact;
